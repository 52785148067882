



import { Component, Vue } from 'vue-property-decorator';
import { WPPost, WPCategories } from '../../types/typescript-axios/api';
import VueRouter from 'vue-router';
import axios, {AxiosResponse, AxiosError} from 'axios';
import Error from '../components/contents/Error.vue';
import CategoryTitle from '../components/contents/categoryTitle/CategoryTitle.vue';
import Breadcrumb from '@/components/contents/breadcrumb/Breadcrumb.vue';
import store from '@/store';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);

let maxLength: number = 0;

async function getDatas(to: VueRouter) {
  let status: number = 200;
  let url: string = '/wp-json/wp/v2/faq';
  let headers: any = {};
  const catUrl: string = '/wp-json/wp/v2/faq_cat';
  maxLength = 0;
  const categoryRes = await axios.get<AxiosResponse>(catUrl);
  const categoriesRes: any = categoryRes.data;
  const $to: any = to;
  let params: any = {};
  let categoryId = -1;

  const aryCheck = categoriesRes.filter((value: any) => {
    if (value.slug === $to.query.category && value.count > 0 && value.parent === 0) {
      return true;
    }
  });

  const categories = categoriesRes.filter((value: any) => {
    if (value.count > 0 && value.parent === 0) {
      return true;
    }
  });

  if (categoriesRes.length > 0) {
    for (const data of categoriesRes) {
      maxLength = maxLength + data.count;
    }
  } else {
    maxLength = 100;
  }
  params.per_page = maxLength;

  let queryName: string = '';

  if (typeof $to.query.category !== 'undefined') {
    if (aryCheck.length <= 0) {
      return {
        status: 404,
      };
    } else {
      categoryId = aryCheck[0].id;
      params.faq_cat = categoryId;
      queryName = $to.query.category;
    }
  }
  if ($to.query.preview) {
    url = `${url}/${$to.query.id}/revisions`;
    params = {
      status: 'auto-draft',
    };
    headers = { 'X-WP-Nonce': window.WP_API_Settings.nonce };
  }
  const res: any = await axios.get<AxiosResponse>(url, {
    params,
    headers,
  });
  const contentsData = res.data;
  status = res.status;

  return {
    status,
    categoryId,
    contentsData,
    categories,
    queryName,
  };
}

@Component({
  components: {
    Breadcrumb,
    Error,
    CategoryTitle,
  },
})
export default class Faq extends Vue {
  private title: any = '';
  private status = 200;
  private breadcrumb: BreadcrumbArr = [
    {
      title: 'HOME',
      link: '/',
    },
    {
      title: 'よくある質問',
      link: '',
    },
  ];
  private queryName: string = '';
  private categoryId: number = 0;
  private categoryUrl: string = '/wp-json/wp/v2/faq?faq_cat=' + this.categoryId;
  private faqContents: WPPost[] = [];
  private categories: WPCategories[] = [];

  public async beforeRouteEnter(to: VueRouter, from: VueRouter, next: any) {
    const data: any = await getDatas(to);
    if (data.status === 200) {
      let title: string = '';
      for (const item of data.categories) {
        if (item.slug) {
          if (item.slug === data.queryName) {
            title = item.name + ' ';
          }
        }
      }
      store.commit('window/setTitle', title + 'よくある質問 ' + process.env.VUE_APP_TITLE_SUFFIX);
      next((vm: {
        faqContents: WPPost[],
        categories: WPPost,
        queryName: string,
        categoryId: number,
        status: number,
      }) => {
        vm.status = data.status;
        vm.faqContents = data.contentsData;
        vm.categories = data.categories;
        vm.queryName = data.queryName;
        vm.categoryId = data.categoryId;
      });
    } else {
      store.commit('window/setTitle', data.status + ' Error よくある質問 ' + process.env.VUE_APP_TITLE_SUFFIX);
      next((vm: {status: number}) => {
        vm.status = data.status;
      });
    }
  }

  public async beforeRouteUpdate(to: VueRouter, from: VueRouter, next: any) {
    const data: any = await getDatas(to);
    this.status = data.status;
    if (data.status === 200) {
      this.faqContents = data.contentsData;
      this.categories = data.categories;
      this.queryName = data.queryName;
      this.categoryId = data.categoryId;
      for (const item of this.categories) {
        if (item.slug) {
          if (item.slug === this.queryName) {
            this.title = item.name;
          }
        } else {
          this.title = '';
        }
      }
      store.commit('window/setTitle', this.title + ' よくある質問 ' + process.env.VUE_APP_TITLE_SUFFIX);
    } else {
      store.commit('window/setTitle', data.status + ' Error よくある質問 ' + process.env.VUE_APP_TITLE_SUFFIX);
    }
    next();
  }
}
